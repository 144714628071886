<template>
  <b-container fluid class="mb-4">
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between mb-4">
          <h4>Detalhes</h4>
        </div>
        <b-card-text class="d-flex flex-column">
          <b-form @submit.prevent="update">
            <b-form-row>
              <b-col>
                <form-input
                  :value="form.razao_social"
                  icon="person-lines-fill"
                  label="Razão Social:"
                  placeholder="Informe a razão social"
                  disabled
                />
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col>
                <form-input
                  v-model="form.telefone"
                  label="Telefone:"
                  icon="telephone"
                />
              </b-col>
              <b-col>
                <form-input
                  :value="form.email"
                  label="Email:"
                  icon="at"
                  disabled
                />
              </b-col>
              <b-col>
                <form-input
                  :value="cpfCnpjMasked"
                  label="CPF/CNPJ:"
                  icon="person-lines-fill"
                  disabled
                />
              </b-col>
              <b-col>
                <form-input
                  :value="formatDate"
                  label="Data de cadastro:"
                  icon="calendar3"
                  disabled
                />
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col>
                <form-input
                  v-model="form.cliente_financeiro[0].desconto_tabela"
                  icon="tag"
                  label="Desconto Tabela:"
                  placeholder="Informe o desconto tabela"
                />
              </b-col>
              <b-col>
                <form-input
                  v-model="form.cliente_financeiro[0].desconto_extra"
                  icon="tag"
                  label="Desconto Extra:"
                  placeholder="Informe o desconto extra"
                />
              </b-col>
              <b-col>
                <form-input
                  v-model="form.cliente_financeiro[0].limite_credito"
                  icon="credit-card"
                  label="Limite de Crédito:"
                  type="number"
                  disabled
                />
              </b-col>
              <b-col>
                <form-input
                  v-model="form.cliente_financeiro[0].saldo_disponivel"
                  icon="cash"
                  label="Saldo Disponivel:"
                  type="number"
                  disabled
                />
              </b-col>
              <b-col>
                <form-input
                  v-model="form.cliente_financeiro[0].debito_aberto"
                  icon="wallet2"
                  label="Debito em aberto:"
                  type="number"
                  disabled
                />
              </b-col>
              <b-col>
                <form-input
                  v-model="form.cliente_financeiro[0].debito_atraso"
                  icon="wallet2"
                  label="Debito em atraso:"
                  type="number"
                  disabled
                />
              </b-col>
              <b-col>
                <form-input
                  v-model="form.cliente_financeiro[0].debito_vencer"
                  icon="wallet2"
                  label="Debito a vencer:"
                  type="number"
                  disabled
                />
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <form-input
                  v-model="form.cliente_endereco[0].endereco"
                  icon="signpost-split"
                  label="Edereço:"
                  placeholder="Informe o endereço"
                />
              </b-col>

              <b-col cols="3">
                <form-input
                  v-model="form.cliente_endereco[0].numero"
                  icon="mailbox"
                  label="Numero:"
                  placeholder="Informe o numero"
                />
              </b-col>
            </b-form-row>

            <b-form-row>
              <b-col>
                <form-input
                  v-model="form.cliente_endereco[0].complemento"
                  icon="card-text"
                  label="Complemento:"
                  placeholder="Informe o complemento"
                />
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col>
                <form-input
                  v-model="form.cliente_endereco[0].bairro"
                  icon="map"
                  label="Bairro:"
                  placeholder="Informe o bairro"
                />
              </b-col>

              <b-col>
                <form-input
                  v-model="form.cliente_endereco[0].cep"
                  icon="signpost-split"
                  label="CEP:"
                  placeholder="Informe o CEP"
                />
              </b-col>
              <b-col>
                <form-input
                  v-model="form.cliente_endereco[0].cidade"
                  icon="pin-map"
                  label="Cidade:"
                  placeholder="Informe a cidade"
                />
              </b-col>
            </b-form-row>

            <b-button variant="primary" type="submit">SALVAR</b-button>
          </b-form>
        </b-card-text>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import dayjs from "dayjs";
import { mapActions, mapMutations, mapState } from "vuex";
import FormInput from "../../components/form/FormInput.vue";
import { maskCpfCnpj } from "../../utils/maskCpfCnpj";

export default {
  components: { FormInput },
  name: "DetailsClient",
  data() {
    return {
      form: JSON.parse(JSON.stringify(this.$store.state.client.client)),
    };
  },
  computed: {
    ...mapState("client", ["client", "isLoading"]),

    cpfCnpjMasked() {
      return maskCpfCnpj(this.form.cpf_cnpj);
    },
    formatDate() {
      return dayjs(this.form.created_at).format("DD/MM/YYYY HH:mm:ss");
    },
  },
  methods: {
    ...mapActions("client", ["getOneClient", "updateInfo"]),
    ...mapMutations(["setOverlay"]),

    async update() {
      const formattedForm = {
        ...this.form,
        endereco_id: this.form.cliente_endereco[0].id,
        endereco: this.form.cliente_endereco[0].endereco,
        numero: this.form.cliente_endereco[0].numero,
        bairro: this.form.cliente_endereco[0].bairro,
        complemento: this.form.cliente_endereco[0].complemento,
        cidade: this.form.cliente_endereco[0].cidade,
        cep: this.form.cliente_endereco[0].cep,
        financeiro_id: this.form.cliente_financeiro[0].id,
        desconto_extra: this.form.cliente_financeiro[0].desconto_extra || "",
        desconto_tabela: this.form.cliente_financeiro[0].desconto_tabela || "",
      };

      delete formattedForm.cliente_endereco;
      delete formattedForm.cliente_financeiro;

      await this.updateInfo({ id: this.form.id, form: formattedForm });
    },
  },
  async created() {
    this.setOverlay(true);
    if (!this.client.id) {
      try {
        const { id } = this.$route.params;
        await this.getOneClient(id);
        this.form = JSON.parse(JSON.stringify(this.$store.state.client.client));
      } catch (erro) {
        console.log(erro);
      }
    }
    this.setOverlay(false);
  },
};
</script>

<style></style>
